import React, { Component } from 'react';
import Carousel from './carousel';
import AboutUs from './aboutus';
// import Faculty from './faculty';
import Course from './coursesection';
import HomeContact from './homeContact';


class Home extends Component {
    render() {
        return (
            <>
                <Carousel />
                <AboutUs />
                {/* <Faculty /> */}
                <Course />
                <HomeContact />
            </>

        );
    }
}

export default Home;
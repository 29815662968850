import React, { Component } from "react";
import { Form } from "react-bootstrap";

class faculty extends Component {
  render() {
    return (
      <div id="faculties">
        <section className="about-us  style-two wow fadeIn">
          <div className="auto-container">
            <div className="row">
              <div className="info-column col-xl-9 col-lg-9 col-md-12 col-sm-12">
                <div className="inner-column" >
                  <div className="sec-title">
                    <span className="title">

                    </span>
                    <h2>Faculty</h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <Form.Control as="select">
                  <option>Undergraduate Courses</option>
                  <option>Postgraduate</option>
                  <optgroup label="Teacher Education">
                    <option>D.El.Ed./BTC</option>
                  </optgroup>
                </Form.Control>
              </div>
            </div>
          </div>
        </section >
      </div >
    );
  }
}

export default faculty;

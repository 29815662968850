import React, { Component } from 'react';
// import img1 from '../../../assets/images/course/ba.jpg';
// import img2 from '../../../assets/images/course/bsc.png';
// import img3 from '../../../assets/images/course/ma.jpg';
// import img4 from '../../../assets/images/course/bed.jpg';
import img5 from '../../../assets/images/course/del.jpg';
import LazyLoad from 'react-lazyload';
import Placeholder from '../../../assets/images/thumbnail-default.jpg';

class Course extends Component {
    render() {
        return (
            <section id="Programs" className="school-section wow fadeIn">
                <div className="auto-container coursepage">
                    <div className="sec-title">
                        <span className="title"></span>
                        <h2>EXPLORE OUR COURSES</h2>
                    </div>
                    <div className="row">
                        {/* <div className="col-lg-4 col-md-6 col-12">
                            <div id="bba" className="single_program">
                                <figure className="position-relative">
                                    <LazyLoad debounce={500} placeholder={<img src={Placeholder} alt="placeholder" />}>
                                        <img className="img-fluid" src={img1} alt="" />
                                    </LazyLoad>
                                    <div className="school_name">
                                        <h4>B.A.<small>| Under Graduate</small></h4>
                                        <div className="scrollbar-inner">
                                            <p> (Bachelor of Arts) (Hindi, Sanskrit, English, Geography, Home Science, Sociology, Ancient History, Urdu, Education, Political Science, Economics) </p>
                                            <p >Annual Intake : <span id="ba">880</span> max</p>
                                            <p>Annual Fees : ₹ 5000</p>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div id="bcomh" className="single_program">
                                <figure className="position-relative">
                                    <LazyLoad debounce={500} placeholder={<img src={Placeholder} alt="placeholder" />}>
                                        <img className="img-fluid" src={img2} alt="" />
                                    </LazyLoad>
                                    <div className="school_name">
                                        <h4>B.Sc.<small>| Under Graduate</small></h4>
                                        <div className="scrollbar-inner">
                                            <p>(Bachelor of Science) (Physics, Chemistry, Mathematics, Zoology, Botany)</p>
                                            <p>Annual Intake : 200 max</p>
                                            <p>Annual Fees : ₹ 5000</p>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div id="bca" className="single_program">
                                <figure className="position-relative">
                                    <LazyLoad debounce={500} placeholder={<img src={Placeholder} alt="placeholder" />}>
                                        <img className="img-fluid" src={img3} alt="" />
                                    </LazyLoad>
                                    <div className="school_name">
                                        <h4>M.A. <small>| Post Graduate</small></h4>
                                        <div className="scrollbar-inner">
                                            <p> (Master of Arts) (Hindi, Sociology, Ancient History, Geography)</p>
                                            <p>Annual Fees : ₹ 8000</p>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div id="mba" className="single_program">
                                <figure className="position-relative">
                                    <LazyLoad debounce={500} placeholder={<img src={Placeholder} alt="placeholder" />}>
                                        <img className="img-fluid" src={img4} alt="fluid" />
                                    </LazyLoad>
                                    <div className="school_name">
                                        <h4>B.Ed.<small>| Under Graduate</small></h4>
                                        <div className="scrollbar-inner">
                                            <p> (Bachelor of Education) </p>
                                            <p>Annual Intake : 100 seats</p>
                                            <p>Annual Fees : ₹ 51250</p>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div> */}
                        <div className="col-lg-4 col-md-6 col-12">
                            <div id="bbamba" className="single_program">
                                <figure className="position-relative">
                                    <LazyLoad debounce={500} placeholder={<img src={Placeholder} alt="placeholder" />}>
                                        <img className="img-fluid" src={img5} alt="course" />
                                    </LazyLoad>
                                    <div className="school_name">
                                        <h4>D.El.Ed./BTC<small> | Integrated</small></h4>
                                        <div className="scrollbar-inner">
                                            <p>(Diploma In Elementary Education)</p>
                                            <p>Annual Intake : 100 seats</p>
                                            <p>Annual Fees : ₹ 41000</p>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Course;
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import * as emailjs from 'emailjs-com';
import mapicon from '../../../src/assets/images/icons/marker-icon.png';
const CustomComponent = ({ text }) => <div><img src={mapicon} alt="marker" /></div>;

class ContactUs extends Component {

  constructor() {
    super();
    this.state = {
      center: {
        lat: 26.1294,
        lng: 82.7858
      },
      zoom: 10,
      text: 'marker',
      apiKey: 'AIzaSyDTWAxQ7Z4GNC28ljvgSDBAmTiZ-eYiGog',
      submitting: false,
      submitted: false,
      error: false,
      buttonState: '',
      formFields: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        text: ''
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onFieldsChange = this.onFieldsChange.bind(this);
    this.onHideSuccess = this.onHideSuccess.bind(this);
    this.onHideError = this.onHideError.bind(this);
    this.successMessage = this.successMessage.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
  }

  onSubmit(e) {
    let { formFields } = this.state;
    e.preventDefault();

    emailjs.send('gmail', 'contact_us', formFields, 'user_T8ucaGcoEhmTATc4WEjM1')
      .then((response) => {
        this.setState({ submitted: true, error: false });
      }, (err) => {
        this.setState({ error: true, submitted: false });
      });
    this.setState({ formFields: { name: '', email: '', phone: '', subject: '', text: '' } });
  }

  onFieldsChange(e) {
    let { formFields } = this.state;
    formFields = { ...formFields };
    formFields[e.target.name] = e.target.value;
    this.setState({ formFields });
  }

  onHideSuccess() {
    this.setState({ submitted: false });
  }

  onHideError() {
    this.setState({ error: false });
  }

  successMessage() {
    if (this.state.submitted) {
      return (
        <div className="alert alert-success">
          <strong>Thank you!</strong>
          <button onClick={this.onHideSuccess} type="button" className="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }

  errorMessage() {
    if (this.state.error) {
      return (
        <div className="alert alert-danger">
          <strong>Something Went Wrong</strong>
          <button onClick={this.onHideError} type="button" className="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }


  renderGoogleMap() {
    const { center, zoom, text, apiKey } = this.state;
    return (
      <div style={{ height: '75vh' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <CustomComponent
            lat={center.lat}
            lng={center.lng}
            text={text}
          />
        </GoogleMapReact>
      </div>
    );
  }

  renderForm() {
    const { formFields: {
      name,
      email,
      phone,
      subject,
      text,
    } } = this.state;

    return (


      <form
        id="contactForm"
        onSubmit={this.onSubmit}
      >
        {this.successMessage()}
        {this.errorMessage()}
        <div className="contact-form mt-3 mt-md-4">
          <div className="form-group">
            <input type="text"
              name="name"
              placeholder="Name"
              data-error="Please enter your name"
              value={name}
              onChange={this.onFieldsChange} required />
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="form-group">
                <input type="email"
                  name="email"
                  placeholder="Email"
                  data-error="Please enter your email"
                  value={email}
                  onChange={this.onFieldsChange} required />
              </div>

            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="form-group">
                <input type="number"
                  name="phone"
                  placeholder="Phone"
                  value={phone}
                  onChange={this.onFieldsChange} required />
              </div>

            </div>
          </div>
          <div className="form-group">
            <input type="text"
              name="subject"
              placeholder="Subject"
              value={subject}
              onChange={this.onFieldsChange} required />
          </div>

          <div className="form-group">
            <textarea name="text"
              id="message"
              cols="30"
              rows="5"
              required
              data-error="Write your message"
              placeholder="Your Message"
              value={text}
              onChange={this.onFieldsChange} />
          </div>

          <div id="msgSubmit" className="form-group">
            <button className="theme-btn btn-style-one" type="submit" name="submit-form">Send Now</button>
          </div>
        </div>
      </form>
    )
  }

  render() {
    return (

      <div id="contact">
        <div className="offset" />
        <section className="contact-area style-two wow fadeIn pb-5 bg1">
          <div className="auto-container">
            <div className="address-wrap">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                  {/* <div className="address-bar"> */}
                  <h4>College Address</h4>
                  <p>
                    <i className="la la-map-marker" /> <b>R. D. Pandey College of Teachers Education </b><br />
                    Laradpur, Oril,  <br />
                    Phoolpur, Azamgarh,<br />
                    Uttar Pradesh-223222, INDIA
                  </p>
                  {/* </div> */}
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                  {/* <div className="address-bar"> */}
                  <h4>Enquiry Now</h4>
                  <p>
                    <a href="mailto:rdptecollege@gmail.com"><i className="la la-at" /> rdptecollege@gmail.com</a>
                  </p>
                  <p>
                    <i className="la la-tty" /> 9473865398, 8419869924
                  </p>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="map-and-form wow fadeIn  mb-0 mb-md-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="google-map">
                  {this.renderGoogleMap()}
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-12">
                <div className="form-column pl-0 pl-md-5 pt-5 pb-5">
                  <div className="inner-column">
                    {this.renderForm()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}


export default ContactUs;
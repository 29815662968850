import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Nlogo from '../../assets/images/Nlogo.png';


class Navbar extends Component {
    constructor() {
        super();
        this.state = {
            isMenuOpen: false,
        };
        this.closeMenu = this.closeMenu.bind(this);
    }

    closeMenu() {
        const { isMenuOpen } = this.state;
        this.setState({ isMenuOpen: !isMenuOpen });
    }
    render() {
        const { isMenuOpen } = this.state;
        return (
            <header className="main-header" >
                {/* <div className="header-top"> */}
                    {/* <div className="auto-container"> */}
                        {/* <div className="inner-container clearfix"> */}
                            {/* <div className="top-left float-right">
                                <ul className="contact-list clearfix"> */}
                                    {/* <li className="mr-0"><a href="#/"><i className="la la-facebook" /></a></li> 
                                    <li className="mr-0"><a href="#/"><i className="la la-instagram" /></a></li>
                                    <li className="mr-0"><a className="text-white" href="#/"><i className="la la-linkedin" /></a></li>
                                    <li><a className="text-white" href="#/"><i className="la la-twitter" /></a></li>  */}
                                 {/* </ul> 
                            </div> */}
                        {/* </div> */}
                    {/* </div> */}
                {/* </div> */}

                {/* Header Lower */}
                <div className="header-lower">
                    <div className="main-box">
                        <div className="auto-container" id="navbar">
                            <div className="inner-container clearfix">
                                <div className="logo-box">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src={Nlogo} alt="logo" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="nav-outer">
                                    <nav className="main-menu navbar-expand-md navbar-light">
                                        <div className="navbar-header" >
                                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                <span className="icon flaticon-menu" />
                                            </button>
                                        </div>
                                        <div className={isMenuOpen ? "collapse navbar-collapse clearfix" : "collapse navbar-collapse clearfix "} id="navbarSupportedContent">
                                            <ul className="navigation clearfix">
                                                <li className="dropdown has-mega-menu">
                                                    <Link to="/" onClick={this.closeMenu}>Home</Link>
                                                </li>
                                                <li className="dropdown has-mega-menu Programs-menu" >
                                                    <Link  to="/about-us" onClick={this.closeMenu}>About Us</Link>
                                                </li>
                                                <li>
                                                    <Link to="/course" onClick={this.closeMenu}>Courses</Link>
                                                </li>
                                                <li>
                                                    <Link to="/facilities" onClick={this.closeMenu}>Facilities </Link>
                                                </li>
                                                {/* <li>
                                                    <Link to="/faculty" onClick={this.closeMenu}>Faculty </Link>

                                                </li> */}
                                                {/* <li>
                                                    <Link to="/faculty"onClick={this.closeMenu}>Faculty </Link>
                                                </li> */}

                                                <li className="dropdown">
                                                    <Link to="/contact-us" onClick={this.closeMenu}>Contact Us</Link>
                                                </li>
                                                {/* <li>
                                                    <Link to="/footer"onClick={this.closeMenu}>Links</Link>
                                                </li> */}
                                                {/* <li>
                                                    <Link to="/"onClick={this.closeMenu}>News & Events</Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                        <div>

                                        </div>

                                    </nav>{/* Main Menu End*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header >

        );
    }
}

export default Navbar;
import React, { Component } from "react";
import LazyLoad from "react-lazyload";
import Placeholder from "../../assets/images/thumbnail-default.jpg";
import computerlab from "../../assets/images/facilities/comp.jpeg";
import lib from "../../assets/images/lib.jpg";
import campus from "../../assets/images/facilities/college_campus.jpg";

class Facilities extends Component {
  render() {
    return (
      <div id="facilities">
        <section className="about-us  style-two wow fadeIn">
          <div className="auto-container">
            <div className="row">
              <div className="info-column col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title ">
                    <span className="title">

                    </span>
                    <h2>FACILITIES</h2>
                  </div>
                  <div className="card">
                    <div className="row mt-4 mt-md-5" id="computerlab">
                      <div className="col-lg-6 col-md-6  col-12 mb-4 mb-md-0">
                        <div className="media float-left">
                          <LazyLoad
                            debounce={500}
                            placeholder={
                              <img src={Placeholder} alt="placeholder" />
                            }
                          >
                            <img
                              src={computerlab}
                              alt="computerlab"
                              className="img-fluid"
                            />
                          </LazyLoad>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6  col-12 ">
                        <div className="faculty-info">
                          <h4>COMPUTER LAB</h4>
                        </div>
                        <div className="text mb-4">
                          <p className=" mb-3">
                            The computing facilities and information technology
                            and instructional support staff are focused on
                            enhancing the learning experience of students. Our
                            goal is to ensure that students of the R. D. Pandey College of
                            Teachers Education will be well prepared to
                            succeed in a technology-rich business world. We
                            provide support for them to have hands-on feel of
                            what they learn in the classrooms and enhance their
                            computer skills and also support research activities
                            undertaken in the college.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="row mt-4 mt-md-5">
                      <div className="col-lg-6 col-md-6  col-12 ">
                        <div className="media float-left">
                          <LazyLoad
                            debounce={500}
                            placeholder={
                              <img src={Placeholder} alt="placeholder" />
                            }
                          >
                            <img
                              src={lib}
                              alt="library"
                              className="img-fluid"
                            />
                          </LazyLoad>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6  col-12 ">
                        <div className="faculty-info">
                          <h4>LIBRARY</h4>
                        </div>
                        <div className="text mb-4 ">
                          <p className=" mb-3">
                            The library is fully computerized using an
                            integrated multi-user library management system
                            where a student can avail the facilities from
                            anywhere from the campus. Several studies point to
                            increased academic success when students use the
                            library. The analysis shows that students who use
                            the library in some way achieve higher levels of
                            academic success .
                          </p>
                          <span>Number of Books: 789 </span>
                          <span style={{ float: "right" }}>
                            Journal Subscribers: 457{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="row mt-4 mt-md-5">
                      <div className="col-lg-6 col-md-6  col-12 ">
                        <div className="media float-left">
                          <LazyLoad
                            debounce={500}
                            placeholder={
                              <img src={Placeholder} alt="placeholder" />
                            }
                          >
                            <img
                              src={campus}
                              alt="campus"
                              className="img-fluid"
                            />
                          </LazyLoad>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6  col-12 ">
                        <div className="faculty-info">
                          <h4>CAMPUS</h4>
                        </div>
                        <div className="text mb-4 ">
                          <p className=" mb-3">
                            The infrastructure development, achievement of
                            academic excellence, quality assurance in the higher
                            education and socio-economic development of this
                            highly backward & rural region of Phoolpur, Azamgarh
                            are some of the priority areas for which the
                            institution is putting its best efforts.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
      </div>
    );
  }
}

export default Facilities;

import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import Slide_1 from '../../../assets/images/slider/1.jpg';
import Slide_2 from '../../../assets/images/slider/2.jpg';
import Slide_3 from '../../../assets/images/slider/3.jpg';

class HomeCarosuel extends Component {
    render() {
        return (
            <Carousel className="main-slider" >
                <Carousel.Item >
                    <img
                        className="d-block w-100 sliderImg"
                        src={Slide_1}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <div className="content-box">
                            {/* <div className="inner-box "> */}
                            {/* <div className="title-box  pt-4 pb-4 pt-md-5 pb-md-5 "> */}
                            {/* <h3 className="mt-0 mt-mb-5 mb-0 mb-md-5">
                                        <span>WELCOME TO OUR </span>
                                        <br />COLLEGE
                                </h3> */}
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                    </Carousel.Caption>
                </Carousel.Item >
                <Carousel.Item>
                    <img
                        className="d-block w-100 sliderImg"
                        src={Slide_2}
                        alt="Second slide"
                    />
                    <Carousel.Caption>
                        <div className="content-box">
                            {/* <div className="inner-box ">
                                <div className="title-box  pt-4 pb-4 pt-md-5 pb-md-5 ">
                                    <h3 className="mt-0 mt-mb-5 mb-0 mb-md-5">
                                        <span>TURN YOUR DREAM </span>
                                        <br />INTO REALITY
                                    </h3>
                                </div>
                            </div> */}
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 sliderImg"
                        src={Slide_3}
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                        <div className="content-box">
                            {/* <div className="inner-box ">
                                <div className="title-box  pt-4 pb-4 pt-md-5 pb-md-5 ">
                                    <h3 className="mt-0 mt-mb-5 mb-0 mb-md-5">
                                        <span>YOUR SUCCESS IS</span>
                                        <br />OUR MISSION
                                    </h3>
                                </div>
                            </div> */}
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel >
        )
    }
}
export default HomeCarosuel;
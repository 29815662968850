import React from 'react';
import { Component } from 'react';
import ScrollToTop from '../src/components/ScrollToTop';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Homepage from './components/pages/home/home';
import Navbar from './components/core/navbar';
import Footer from './components/core/footer';
import AboutUs from './components/pages/aboutUs';
import Course from './components/pages/courses/course';
import Facilities from './components/pages/facilities';
import Events from './components/pages/newsNevents';
import ContactUs from './components/pages/contactUs';
import Faculty from './components/pages/faculty';
import Students from './components/pages/studentsList';


import 'bootstrap/dist/css/bootstrap.min.css';
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <>
        <ScrollToTop>
          <Navbar />
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/facilities" component={Facilities} />
            <Route exact path="/course" component={Course} />
            <Route exact path="/" component={Events} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/faculty" component={Faculty} />
            <Route exact path="/students" component={Students} />
          </Switch>
          <Footer />
          </ScrollToTop>
        </>
      </BrowserRouter>
    );
  }
}

export default App;

import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import Slide_1 from '../../../assets/images/slider/1.jpg';
// import Placeholder from '../../../assets/images/thumbnail-default.jpg';

class AboutUs extends Component {
    render() {
        return (<section className="about-us style-two wow fadeIn" >
            <div className="auto-container">
                <div className="row">
                    <div className="info-column col-xl-6 col-lg-7 col-md-12 col-sm-12">
                        {/* <div className="inner-column"> */}
                        {/* <div className="sec-title "> */}
                        {/* <span className="title">R. D. Pandey College of Teachers Education</span> */}
                        <h2>About College</h2>
                        {/* </div> */}
                        <div className="text">
                            R. D. Pandey College of Teachers Education, Laradpur, Oril, Phoolpur, Azamgarh in the honour of Shri Ashok Kumar Pandey ji,
                            was established in 2013, run by Shri Ashok Kumar Pandey Manager and Shri Ramdawar Anusuchit Vidyalaya evam Shiksha Samiti Trust
                            established on 31st March, 1995. <br />
                            Continuous qualitative and quantitative growth, excellence in academic and administrative activities.
                            Started with academic course i.e. D.El.Ed./BTC also known as B.T.C.
                        </div>
                        {/* </div> */}
                    </div>
                    {/* Video Column */}
                    <div className="video-column col-xl-6 col-lg-5 col-md-12 col-sm-12">
                        <div className="inner-column">
                            {/* <div className="video-box"> */}
                            <div className="video-patch d-block" style={{ width: '90%' }}></div>
                            <LazyLoad debounce={500}  >
                                <img src={Slide_1} id="aboutus_img" alt="college" />
                            </LazyLoad>

                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </section>)
    }
}
export default AboutUs;
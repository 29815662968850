import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import Placeholder from '../../assets/images/thumbnail-default.jpg';
import college from '../../../src/assets/images/aboutus/Main_Building.jpg';
import founder from '../../../src/assets/images/aboutus/ashok_kumar_pandey.jpg';

const faculties = [

  {
    Name: "Shri Ramawadh",
    Designation: "President"
  },
  {
    Name: "Shri Ramalagan",
    Designation: "Vice President"
  },
  {
    Name: "Shri Ashok Kumar Pandey",
    Designation: "Manager"
  },
  {
    Name: "Shri Ramapati Mishra",
    Designation: "Vice Manager"
  },
  {
    Name: "Shri Satyanarayan",
    Designation: "Treasurer"
  },
  {
    Name: "Shri Saroj Kumar",
    Designation: "Secretary"
  },
  {
    Name: "Shri Anil Kumar",
    Designation: "Investigator"
  },
  {
    Name: "Shri Radheshyam",
    Designation: "Member"
  },
  {
    Name: "Shri Kamala Prasad",
    Designation: "Member"
  },
  {
    Name: "Shri Lalti",
    Designation: "Member"
  },
  {
    Name: "Shri Prabhawati",
    Designation: "Member"
  },
  {
    Name: "Shri Shiv Kumar",
    Designation: "Member"
  },
  {
    Name: "Shri Subhawati",
    Designation: "Member"
  },
  {
    Name: "Shri Ramagya Pandey",
    Designation: "Member"
  },

  {
    Name: "Shri Rajmani",
    Designation: "Member"
  },

];

class AboutUs extends Component {

  render() {
    return (
      <div id="about">
        <section id={1} className="about-us style-two wow fadeIn">
          <div className="auto-container">
            <div className="row">
              <div className="info-column col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title ">
                    <span className="title">KNOW MORE ABOUT</span>
                    <h2> OUR CAMPUS</h2>
                  </div>
                  <div className="row mt-4 mt-md-5">
                    <div className="col-lg-6 col-md-6  col-12 mb-4 mb-md-0">
                      <div className="media float-left">
                        <LazyLoad debounce={500} placeholder={<img src={Placeholder} alt="placeholder" />}>
                          <img src={college} alt="college" className="img-fluid" />
                        </LazyLoad>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="text mb-4">
                        <p className=" mb-3">
                          R. D. Pandey College of Teachers Education, Laradpur, Oril, Phoolpur, Azamgarh in the honour of Shri Ashok Kumar Pandey ji,
                          was established in 2013, run by Shri Ashok Kumar Pandey Manager and Shri Ramdawar Anusuchit Vidyalaya evam Shiksha Samiti Trust
                          established on 31st March, 1995. Continuous qualitative and quantitative growth, excellence in academic and administrative activities.
                          Started with academic course i.e. D.El.Ed./BTC also known as B.T.C.<br />
                          The college is located in Village Laradpur, Post Oril, and Tehsil Phoolpur, (45 Km from District Azamgarh). Institute is well connected by road.
                          The infrastructure development, achievement of academic excellence, quality assurance in the higher education and socio-economic development of this highly backward & rural region of Phoolpur,
                          Azamgarh are some of the priority areas for which the institution is putting its best efforts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="auto-container mt-4 mt-md-5">
          <div className="row">
            <div className="info-column col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title ">
                  <span className="title"></span>
                  <h2>FOUNDER & MANAGER</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="about-us style-two wow fadeIn">
          <div className="auto-container position-relative">

            <div className="our-founder-colm1-right">
              <LazyLoad debounce={500} placeholder={<img src={Placeholder} alt="placeholder" />}>
                <img src={founder} alt="FounderImage" />
              </LazyLoad>
            </div>
            <div className="chairman-name">
              <abbr>Shri Ashok Kumar Pandey <br />
                Founder/Manager<br />
                Village Laradpur Post Oril, Tehsil Phoolour,
                <br />District Azamgarh, U.P. 223222
                <br /><br />Contact : 9452517828
                <br />Email: a.k.pandey630@gmail.com </abbr>
            </div>
            <div className="our-founder-colm1-left">
              <p>
                Our mission to build a grade educational institution to provide facilities or faculty, curriculum or career guidance, we seek to offer the very best to our students. This college is based on hard work with smart thinking, open communication, team work and high level of responsibility. The service of the institution in creating personally grown-up, become adroit professionals, academic quality and be welfare to society. </p>
            </div>
            <div className="clearfix"></div>
            <div className="clearfix" />
          </div>
        </section>

        <div className="auto-container mt-4 mt-md-5">
          <div className="row">
            <div className="info-column col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title ">
                  <span className="title"></span>
                  <h2> MANAGING COMMITTEE </h2>
                </div>
                <div className="table-responsive table-custom table_custom mt-4 mt-md-5">
                  <table className="table table-bordered text-center align-middle">
                    <thead>
                      <tr>
                        <th className="align-middle" rowSpan={2}>Name</th>
                        <th className="align-middle" colSpan={2}>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        faculties.map(faculty => (
                          <tr key={faculty.Name}>
                            <td className="align-middle" width="50%" >
                              <ul className="list align-middle">
                                <li>{faculty.Name}</li>
                              </ul>
                            </td>
                            <td className="align-middle" colSpan={2}>{faculty.Designation}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default AboutUs;